<template>
  <v-container>
    <v-row>
      <!-- page header -->
      <v-col cols="12">
        <v-row>
          <v-col cols="6"
            ><h1 class="display-1 font-weight-medium">
              Upload Inventory Listings
            </h1>
            <span class="d-block mt-4">1. Select inventory source </span>
            <span class="d-block"
              >2. Drag and drop CSV file onto dropzone
            </span>
            <span class="d-block">3. Inventory will automatically upload </span>
          </v-col>
        </v-row>
      </v-col>

      <!-- autocomplete -->
      <v-col cols="6">
        <v-autocomplete
          label="Select Inventory Source"
          outlined
          dense
          :items="sourceItem"
          v-model="source"
        ></v-autocomplete>
      </v-col>

      <!-- dropzone -->
      <v-col
        cols="12"
        v-cloak
        @drop.prevent="onDropFile"
        @dragover.prevent
        v-if="source"
      >
        <v-card outlined style="border: 2px dotted" :loading="loading">
          <v-card-text class="text--primary text-center">
            <h3 class="title my-3">
              Drop a CSV file here to automatically upload it
            </h3>
            <p class="body-1 font-weight-medium mt-5 mb-1">
              All existing data for that property ID will be overwritten
            </p>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- error -->
      <v-col cols="12">
        <v-alert
          v-if="error"
          dense
          text
          type="error"
          outlined
          icon="mdi-alert-circle-outline"
        >
          <span class="font-weight-bold">Error:</span> {{ error }}
        </v-alert>
      </v-col>

      <!-- jobComplete -->
      <v-col cols="12">
        <v-alert
          v-if="jobComplete"
          dense
          text
          type="success"
          outlined
          icon="mdi-check-circle-outline"
        >
          <strong>Job Complete!</strong>
          <span class="primary--text font-weight-bold">
            {{ this.jobComplete }}</span
          >
          uploaded successfully. Drop another file to upload if needed.
        </v-alert>
      </v-col>

      <!-- template warning -->
      <v-col cols="12">
        <v-alert v-if="source" dense text type="info" outlined :icon="false">
          <p class="mt-2 d-flex">
            <span class="font-weight-bold d-block mr-1">Important:</span>
            <span
              class="text-capitalize font-weight-bold red--text d-block mr-1"
            >
              {{ source }}
            </span>
            <span class="d-block">
              inventory listings must use a template to be uploaded.</span
            >
            <span>Please ensure your</span>
            <v-spacer />
            <v-btn
              href="https://drive.google.com/file/d/1GkRndjF-3GK-RH9WKfY0lKL2l5A5ZBHm/view?usp=sharing"
              target="_blank"
              color="primary"
              depressed
              small
              v-if="isManual"
              >Download Template</v-btn
            >
            <v-btn
              href="https://firebasestorage.googleapis.com/v0/b/silent-auction-vacations-9b380.appspot.com/o/csvTemplates%2FInventory%20Template.csv?alt=media&token=6d939265-3a69-43a5-aa10-b49b4805d50e"
              target="_blank"
              color="primary"
              depressed
              small
              v-else
              >Download Template</v-btn
            >
          </p>
          <p>
            <v-card outlined rounded v-if="isManual">
              <p class="body-2 pl-3 pt-2">Example Template Values</p>
              <v-data-table
                dense
                hide-default-footer
                :items="[
                  {
                    resortPropertyId: 'BANY (uppercase)',
                    checkInDate: checkInDateExample,
                    roomCost: 975,
                    bedrooms: 1,
                    maxOccupancy: 4,
                  },
                ]"
                :headers="[
                  {
                    text: 'resortPropertyId',
                    value: 'resortPropertyId',
                    sortable: false,
                  },
                  {
                    text: 'checkInDate',
                    value: 'checkInDate',
                    sortable: false,
                  },
                  { text: 'roomCost', value: 'roomCost', sortable: false },
                  { text: 'bedrooms', value: 'bedrooms', sortable: false },
                  {
                    text: 'maxOccupancy',
                    value: 'maxOccupancy',
                    sortable: false,
                  },
                ]"
              >
              </v-data-table>
            </v-card>
            <v-card outlined rounded v-else>
              <p class="body-2 pl-3 pt-2">Example Template Values</p>
              <v-data-table
                dense
                hide-default-footer
                :items="[
                  {
                    resortPropertyId: 'BANY (uppercase)',
                    dates: 'Jun 19 2026 - Jun 23 2026',
                    roomCost: 975,
                    bedrooms: 1,
                    maxOccupancy: 4,
                    kitchen: 'full',
                  },
                ]"
                :headers="[
                  {
                    text: 'resortPropertyId',
                    value: 'resortPropertyId',
                    sortable: false,
                  },
                  {
                    text: 'dates',
                    value: 'dates',
                    sortable: false,
                  },
                  { text: 'roomCost', value: 'roomCost', sortable: false },
                  { text: 'bedrooms', value: 'bedrooms', sortable: false },
                  { text: 'kitchen', value: 'kitchen', sortable: false },
                  {
                    text: 'maxOccupancy',
                    value: 'maxOccupancy',
                    sortable: false,
                  },
                ]"
              >
              </v-data-table>
            </v-card>
          </p>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);
const sourceDoc = portalsApp.firestore().collection("sources");
import { portalsApp } from "@/firebase";
export default {
  async mounted() {
    await this.$bind("sources", sourceDoc);
    this.sourceItem = [
      ...this.sources.map((el) => el.name),
      ...this.sourceItem,
    ];
  },
  data: () => ({
    sources: [],
    sourceItem: [
      "castlemartyr",
      "dreamweeks",
      "gardens at west maui",
      "kahana villa vacation club",
      "knightsbrook",
      "maui banyan vacation club",
      "maui beach vacation club",
      "sands of kahana resort",
      "grand ferdinand vienna",
      "ambassador hotel vienna",
      "vienna marriot",
      "exe international palace rome",
      "hotel mancino 12 rome",
      "napolean hotel rome",
      "paris marriot opera ambassador",
      "golden tulip hotel washington opera paris",
      "lempire paris",
      "grandior hotel prague",
      "hilton prague old town",
      "michelangelo grand hotel prague",
      "paris las vegas",
      "mandalay bay las vegas",
      "caesars palace las vegas",
      "mirage las vegas",
      "mgm grand las vegas",
      "cosmopolitan las vegas",
      "bayshore villas bali",
      "holston house nashville",
      "renaissance nashville",
      "gaylord opryland resort",
      "the inn at opryland",
      "millennium maxwell house",
      "the capitol hotel an ascend hotel",
      "the guest house at graceland",
      "hu hotel",
      "crowne plaza hotel memphis downtown",
      "sheraton memphis downtown",
      "kahana falls resort",
      "pono kai resort",
      "the makai club",
      "johnstown estate",
      "hotel hendricks macys",
      "hotel hendricks",
      "the manhattan at times square hotel macys",
      "the manhattan at times square hotel",
      "row nyc macys",
      "row nyc",
      "hotel riu plaza new york times square macys",
      "hotel riu plaza new york times square",
      "royal decameron cornwall beach all inclusive",
      "bahia principe grand jamaica all inclusive",
      "east clare golf village",
      "sun peaks grand hotel",
      "be live collection punta cana all inclusive - adults only",
      "sunscape puerto plata dominican republic all inclusive",
      "occidental cozumel all inclusive",
      "the pinnacle lodge",
      "casa dorada cabo real",
      "the royal cancun",
      "indian palms vacation club",
      "palm canyon resort",
      "london hilton on park lane",
      "royal sonesta",
      "the westin cape coral resort at marina village",
      "the atlantic hotel and spa",
      "eden roc miami beach",
      "desert breezes resort DBR",
      "desert breezes resort DZ2",
      "westgate town center",
      "indian palms intervals",
      "blue tree resort at lake buena vista",
      "heyward mews holiday homes",
      "knightsbrook kbch",
      "embarc panorama",
      "panorama horsethief",
      "design suites buenos aires",
      "karma borgo di colleoli - standard unit",
      "karma borgo di colleoli - garden unit",
    ],
    error: "",
    jobComplete: "",
    source: "",
    missingFieldName: "",
  }),
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    checkInDateExample() {
      return moment().format("MM/DD/YYYY");
    },
    isManual() {
      return !this.sources.some((el) => el.name == this.source);
    },
  },
  methods: {
    trimAll(item) {
      if (item)
        return item
          .replace(/(?! )\s/g, "")
          .replace(/[\t ]+\</g, "<")
          .replace(/&nbsp;/gi, "")
          .replace(/ +(?= )/g, "");
      return "";
    },
    async onDropFile(e) {
      let file = e.dataTransfer.files[0];
      if (e.dataTransfer.files.length > 1)
        return (this.error = "Please upload 1 file at a time");
      // if (file.type !== "application/vnd.ms-excel")
      //   return (this.error = "Only CSV files accepted");
      await this.$store.dispatch("setLoading", true);
      this.$papa.parse(file, {
        header: true,
        complete: (results) => {
          this.formatAndSubmitData(results);
        },
      });
    },
    formatAndSubmitData(results) {
      const source = this.source;
      const isNewSource = this.sources.some((el) => el.name == source);
      if (isNewSource) {
        this.onManual(results);
      } else {
        switch (source) {
          case "manual":
            this.onManual(results);
            break;
          case "dreamweeks":
            this.onDreamweeks(results);
            break;
          case "castlemartyr":
            this.onCastlemartyr(results);
            break;
          case "knightsbrook":
            this.onKnightsbrook(results);
            break;
          case "maui banyan vacation club":
            this.onMauiBanyan(results);
            break;
          case "gardens at west maui":
            this.onGardensAtWestMaui(results);
            break;
          case "kahana villa vacation club":
            this.onKahanaVillaVacationClub(results);
            break;
          case "maui beach vacation club":
            this.onMauiBeachVacationClub(results);
            break;
          case "sands of kahana resort":
            this.onSandsOfKahana(results);
            break;
          case "bayshore villas bali":
            this.onBayshoreVillasBali(results);
            break;
          case "cosmopolitan las vegas":
            this.onCosmopolitanLasVegas(results);
            break;
          case "mgm grand las vegas":
            this.onMgmGrandLasVegas(results);
            break;
          case "mirage las vegas":
            this.onMirageLasVegas(results);
            break;
          case "caesars palace las vegas":
            this.onCaesarsPalaceLasVegas(results);
            break;
          case "mandalay bay las vegas":
            this.onMandalayBayLasVegas(results);
            break;
          case "paris las vegas":
            this.onParisLasVegas(results);
            break;
          case "michelangelo grand hotel prague":
            this.onMichelangeloGrandHotelPrague(results);
            break;
          case "hilton prague old town":
            this.onHiltonPragueOldTown(results);
            break;
          case "grandior hotel prague":
            this.onGrandiorHotelPrague(results);
            break;
          case "lempire paris":
            this.onLempireParis(results);
            break;
          case "golden tulip hotel washington opera paris":
            this.onGoldenTulipHotelWashingtonOperaParis(results);
            break;
          case "paris marriot opera ambassador":
            this.onParisMarriotOperaAmbassador(results);
            break;
          case "napolean hotel rome":
            this.onNapoleanHotelRome(results);
            break;
          case "hotel mancino 12 rome":
            this.onHotelMancinoRome(results);
            break;
          case "exe international palace rome":
            this.onExeInternationalPalaceRome(results);
            break;
          case "vienna marriot":
            this.onViennaMarriot(results);
            break;
          case "ambassador hotel vienna":
            this.onAmbassadorHotelVienna(results);
            break;
          case "grand ferdinand vienna":
            this.onGrandFerdinandVienna(results);
            break;
          case "holston house nashville":
            this.onHolstonHouseNashville(results);
            break;
          case "renaissance nashville":
            this.onRenaissanceNashville(results);
            break;
          case "gaylord opryland resort":
            this.onGaylordOprylandResort(results);
            break;
          case "the inn at opryland":
            this.onTheInnAtOpryland(results);
            break;
          case "millennium maxwell house":
            this.onMillenniumMaxwellHouse(results);
            break;
          case "the capitol hotel an ascend hotel":
            this.onTheCapitolHotelAnAscendHotel(results);
            break;
          case "the guest house at graceland":
            this.onTheGuestHouseAtGraceland(results);
            break;
          case "hu hotel":
            this.onHuHotel(results);
            break;
          case "crowne plaza hotel memphis downtown":
            this.onCrownePlazaHotelMemphisDowntown(results);
            break;
          case "sheraton memphis downtown":
            this.onSheratonMemphisDowntown(results);
            break;
          case "kahana falls resort":
            this.onKahanaFallsResort(results);
            break;
          case "pono kai resort":
            this.onPonoKaiResort(results);
            break;
          case "the makai club":
            this.onTheMakaiClub(results);
            break;
          case "johnstown estate":
            this.onJohnstownEstate(results);
            break;
          case "hotel hendricks macys":
            this.onHotelHendricksMacys(results);
            break;
          case "hotel hendricks":
            this.onHotelHendricks(results);
            break;
          case "the manhattan at times square hotel macys":
            this.onTheManhattanMacys(results);
            break;
          case "the manhattan at times square hotel":
            this.onTheManhattan(results);
            break;
          case "row nyc macys":
            this.onRowNycMacys(results);
            break;
          case "row nyc":
            this.onRowNyc(results);
            break;
          case "hotel riu plaza new york times square macys":
            this.onHotelRiuMacys(results);
            break;
          case "hotel riu plaza new york times square":
            this.onHotelRiu(results);
            break;
          case "royal decameron cornwall beach all inclusive":
            this.onRoyalDecameron(results);
            break;
          case "bahia principe grand jamaica all inclusive":
            this.onBahiaPrincipe(results);
            break;
          case "east clare golf village":
            this.onEastClareGolfVillage(results);
            break;
          case "sun peaks grand hotel":
            this.onSunPeaksGrandHotel(results);
            break;
          case "be live collection punta cana all inclusive - adults only":
            this.onBeLiveCollection(results);
            break;
          case "sunscape puerto plata dominican republic all inclusive":
            this.onSunscapePuertoPlata(results);
            break;
          case "occidental cozumel all inclusive":
            this.onOccidentalCozumel(results);
            break;
          case "the pinnacle lodge":
            this.onThePinnacleLodge(results);
            break;
          case "casa dorada cabo real":
            this.onCasaDorado(results);
            break;
          case "the royal cancun":
            this.onTheRoyalCancun(results);
            break;
          case "indian palms vacation club":
            this.onIndianPalmsVacationClub(results);
            break;
          case "palm canyon resort":
            this.onPalmCanyonResort(results);
            break;
          case "london hilton on park lane":
            this.onLondonHilton(results);
            break;
          case "royal sonesta":
            this.onRoyalSonesta(results);
            break;
          case "the westin cape coral resort at marina village":
            this.onTheWestinCapeCoral(results);
            break;
          case "the atlantic hotel and spa":
            this.onTheAtlantic(results);
            break;
          case "eden roc miami beach":
            this.onEdenRocMiamiBeach(results);
            break;
          case "desert breezes resort DBR":
            this.onDesertBreezesResortDBR(results);
            break;
          case "desert breezes resort DZ2":
            this.onDesertBreezesResortDZ2(results);
            break;
          case "westgate town center":
            this.onWestgateTownCenter(results);
            break;
          case "indian palms intervals":
            this.onIndianPalmsIntervals(results);
            break;
          case "blue tree resort at lake buena vista":
            this.onBlueTreeResortAtLakeBuenaVista(results);
            break;
          case "heyward mews holiday homes":
            this.onHeywardMewsHolidayHomes(results);
            break;
          case "knightsbrook kbch":
            this.onKnightsbrookKbch(results);
            break;
          case "embarc panorama":
            this.onEmbarcPanorama(results);
            break;
          case "panorama horsethief":
            this.onPanoramaHorsethief(results);
            break;
          case "design suites buenos aires":
            this.onDesignSuitesBuenosAires(results);
            break;
          case "karma borgo di colleoli - standard unit":
            this.onKarmaStandard(results);
            break;
          case "karma borgo di colleoli - garden unit":
            this.onKarmaGarden(results);
            break;
          default:
            this.error = "property ID not recognized";
            this.$store.state.loading = false;
        }
      }
    },
    async onManual(results) {
      // not manual inventory
      if (!results.data) {
        this.error = `Not ${this.source} Inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // Remove blank Line
      data = data.filter((i) => {
        if (
          i.resortPropertyId ||
          i.checkInDate ||
          i.roomCost ||
          i.bedrooms ||
          i.maxOccupancy
        ) {
          return i;
        }
      });

      // Required fields
      const requiredFields = [
        "resortPropertyId",
        "roomCost",
        "bedrooms",
        "dates",
        "maxOccupancy",
        "kitchen",
      ];
      let errMsg = "";

      // Check if all objects in the array have the required fields
      const allObjectsHaveRequiredFields = data.every((obj) => {
        return requiredFields.every((field) => {
          if (!obj.hasOwnProperty(field) || !obj[field]) errMsg = field;
          return obj.hasOwnProperty(field) && obj[field];
        });
      });

      if (!allObjectsHaveRequiredFields) {
        this.error = `Seems like ${errMsg} is missing. Please update the details and try again.`;
        await this.$store.dispatch("setLoading", false);
        return;
      }
      this.error = "";

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === i.resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // Example array with objects

      // checkin required fields

      // get inventory property
      let propertyCheck = portalsApp
        .firestore()
        .collection("inventoryProperties")
        .doc(data[0].resortPropertyId.toUpperCase());

      propertyCheck = await propertyCheck.get();
      const inventory = propertyCheck.data();
      console.log("destination",inventory,propertyCheck);

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        // checkInDate
        i.checkInDate = i.dates.split("-")[0].trim();
        i.checkInDate = moment(i.checkInDate, "MMM DD YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        // checkInDate

        // checkOutDate
        i.checkOutDate = i.dates.split("-")[1].trim();
        i.checkOutDate = moment(i.checkOutDate, "MMM DD YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkOutDate = new Date(i.checkOutDate)
          .toISOString()
          .substring(0, 10);
        // checkOutDate
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = i.destination ? i.destination : inventory.destination;

        i.id =
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9);

        var date1 = moment(i.checkOutDate);
        var date2 = moment(i.checkInDate);

        // Subtract checkOutDate from checkInDate
        var differenceNights = date1.diff(date2, "days");
        i.kitchen = i.kitchen;
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = differenceNights;
        i.resortAddress = i.resortAddress || inventory.resortAddress;
        i.resortName = i.resortName || inventory.resortName;
        i.resortPropertyId = i.resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = this.source;
      });
      console.log("data[0].resortPropertyId,",data[0].resortPropertyId)
      let firestoreData = {
        template: "inventoryListings",
        id: data[0].resortPropertyId.toUpperCase(),
        destination: data[0].destination,
        resortName: data[0].resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: this.source,
      };

      let checkId = await portalsApp
        .firestore()
        .collection("inventoryListings")
        .where("id", "==", firestoreData.id.toUpperCase())
        .get();

        console.log("first",checkId)
      if (!checkId.docs.length) {
        this.error = `Id :${firestoreData.id.toUpperCase()} not exist`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(firestoreData.id.toUpperCase());

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = data[0].resortPropertyId;
    },
    async onKarmaGarden(results) {
      // vars
      let resortPropertyId = "BDRC2";
      let resortName = "Karma Borgo di Colleoli - Garden Unit";
      let destination = "Tuscany, Italy";
      let numNights = 7;
      let resortAddress = "Via Panoramica, 20, 56036 Colleoli PI, Italy";
      let source = "tripbeat";

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9);

        i.kitchen = "full";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = source;
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onKarmaStandard(results) {
      // vars
      let resortPropertyId = "BDRC";
      let resortName = "Karma Borgo di Colleoli - Standard Unit";
      let destination = "Tuscany, Italy";
      let numNights = 7;
      let resortAddress = "Via Panoramica, 20, 56036 Colleoli PI, Italy";
      let source = "tripbeat";

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9);

        i.kitchen = "full";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = source;
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onDesignSuitesBuenosAires(results) {
      // vars
      let resortPropertyId = "DSBA";
      let resortName = "Design Suites Buenos Aires";
      let destination = "Buenos Aires, Argentina";
      let numNights = 7;
      let resortAddress =
        "Tacuari 243, San Telmo, Buenos Aires, Capital Federal, 1071";
      let source = "hc";

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9);

        i.kitchen = "limited";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = source;
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onPanoramaHorsethief(results) {
      // vars
      let resortPropertyId = "HCHT";
      let resortName = "Panorama Vacation Retreat at Horsethief Lodge";
      let destination = "Panorama, BC, Canada";
      let numNights = 7;
      let resortAddress = "2000 Panorama Drive, Panorama, BC, V0A 1T0";
      let source = "hc";

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9);

        i.kitchen = "limited";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = source;
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onEmbarcPanorama(results) {
      // vars
      let resortPropertyId = "HCEM";
      let resortName = "Embarc Panorama";
      let destination = "Panorama, BC, Canada";
      let numNights = 7;
      let resortAddress = "2000 Panorama Drive, Panorama, BC, V0A 1T0";
      let source = "hc";

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9);

        i.kitchen = "limited";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = source;
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },

    async onKnightsbrookKbch(results) {
      // vars
      let resortPropertyId = "KBCH";
      let resortName = "Knightsbrook Hotel Spa and Golf Resort";
      let destination = "Dublin Area, Ireland";
      let numNights = 7;
      let resortAddress = "Dublin Road, Trim, Co. Meath, C15 WYF4, Ireland";
      let source = "kbr";

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9);

        i.kitchen = "full";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = source;
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onHeywardMewsHolidayHomes(results) {
      // vars
      let resortPropertyId = "MEWS";
      let resortName = "Heyward Mews Holiday Homes";
      let destination = "Swords, Co. Dublin, Ireland";
      let numNights = 7;
      let resortAddress =
        "10 Heyward Mews, Roganstown, Swords, Co. Dublin, Ireland";
      let source = "mews";

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9);

        i.kitchen = "full";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = source;
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },

    async onBlueTreeResortAtLakeBuenaVista(results) {
      // vars
      let resortPropertyId = "BLT";
      let resortName = "Blue Tree Resort at Lake Buena Vista";
      let destination = "Lake Buena Vista, Florida";
      let numNights = 7;
      let resortAddress = "12007 Cypress Run Drive Orlando, FL  32836";
      let source = "dw";

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = source;
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },

    async onIndianPalmsIntervals(results) {
      // vars
      let resortPropertyId = "IPI";
      let resortName = "Indian Palms Intervals";
      let destination = "Indio, California";
      let numNights = 7;
      let resortAddress = "82-954 Stewart Drive Indio, CA  92201";
      let source = "dw";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = source;
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onWestgateTownCenter(results) {
      // vars
      let resortPropertyId = "WTG";
      let resortName = "Westgate Town Center";
      let destination = "Kissimmee, FL, USA";
      let numNights = 7;
      let resortAddress = "2770 Old Lake Wilson Road Kissimmee, FL  34747";
      let source = "dw";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = source;
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onDesertBreezesResortDZ2(results) {
      // vars
      let resortPropertyId = "DZ2";
      let resortName = "Desert Breezes Resort";
      let destination = "Palm Desert, CA, USA";
      let numNights = 7;
      let resortAddress =
        "77-955 Calle Las Brisas South Palm Desert, CA  92211-8265";
      let source = "dw";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = source;
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onDesertBreezesResortDBR(results) {
      // vars
      let resortPropertyId = "DBR";
      let resortName = "Desert Breezes Resort";
      let destination = "Palm Desert, CA, USA";
      let numNights = 7;
      let resortAddress =
        "77-955 Calle Las Brisas South Palm Desert, CA  92211-8265";
      let source = "dw";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = source;
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onTheWestinCapeCoral(results) {
      // vars
      let resortPropertyId = "TWCC";
      let resortName = "The Westin Cape Coral Resort At Marina Village";
      let destination = "Cape Coral, FL";
      let numNights = 5;
      let resortAddress = "5951 Silver King Blvd., Cape Coral, FL, 33914";
      let source = "hc";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = source;
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onTheAtlantic(results) {
      // vars
      let resortPropertyId = "TAHS";
      let resortName = "The Atlantic Hotel and Spa";
      let destination = "Fort Lauderdale, FL";
      let numNights = 5;
      let resortAddress =
        "601 N Fort Lauderdale Beach Blvd, Fort Lauderdale, FL, 33304";
      let source = "hc";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = source;
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onEdenRocMiamiBeach(results) {
      // vars
      let resortPropertyId = "ERMB";
      let resortName = "Eden Roc Miami Beach";
      let destination = "Miami Beach, FL";
      let numNights = 5;
      let resortAddress = "4525 Collins Ave, Miami Beach, FL, 33140";
      let source = "hc";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = source;
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onRoyalSonesta(results) {
      // vars
      let resortPropertyId = "HCRS";
      let resortName = "Royal Sonesta New Orleans";
      let destination = "New Orleans, Louisiana";
      let numNights = 3;
      let resortAddress = "300 Bourbon St, New Orleans, LA, 70130";
      let source = "hc";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = source;
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onLondonHilton(results) {
      // vars
      let resortPropertyId = "LHPL";
      let resortName = "London Hilton on Park Lane";
      let destination = "London, England";
      let numNights = 4;
      let resortAddress = "22 Park Lane, London, England, W1K 1BE";
      let source = "hc";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = source;
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onPalmCanyonResort(results) {
      // vars
      let resortPropertyId = "RIS";
      let resortName = "Palm Canyon Resort";
      let destination = "Palm Springs, California";
      let numNights = 7;
      let resortAddress =
        "2800 South Palm Canyon Drive Palm Springs, CA  92264";
      let source = "dreamweeks";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = source;
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onIndianPalmsVacationClub(results) {
      // vars
      let resortPropertyId = "INP";
      let resortName = "Indian Palms Vacation Club";
      let destination = "Indio, California";
      let numNights = 7;
      let resortAddress = "48-630 Monroe Street Indio, CA  92201";
      let source = "dreamweeks";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = source;
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onTheRoyalCancun(results) {
      // vars
      let resortPropertyId = "CII";
      let resortName = "The Royal Cancún";
      let destination = "Cancún, Quintana Roo, Mexico";
      let numNights = 7;
      let resortAddress =
        "Kukulcán Boulevard Secc. C2 Cancún, Quintana Roo 77500  Mexico";
      let source = "dreamweeks";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = source;
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onCasaDorado(results) {
      // vars
      let resortPropertyId = "DME";
      let resortName = "Casa Dorada Cabo Real";
      let destination = "Baja California Sur, Mexico";
      let numNights = 7;
      let resortAddress =
        "Ctra. Transpeninsular SJC-CSL Km. 18.5 San José del Cabo, Baja California Sur 23400  Mexico";
      let source = "dreamweeks";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = source;
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onThePinnacleLodge(results) {
      // vars
      let resortPropertyId = "PNG";
      let resortName = "The Pinnacle Lodge";
      let destination = "British Columbia, Canada";
      let numNights = 7;
      let resortAddress = "2503 Eagle Court, Sun Peaks, V0E5N0, BC, Canada";
      let source = "hc";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = source;
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onBeLiveCollection(results) {
      // vars
      let resortPropertyId = "AODR";
      let resortName =
        "Be Live Collection Punta Cana All Inclusive - Adults Only";
      let destination = "Punta Cana, Dominican Republic";
      let numNights = 4;
      let resortAddress =
        "Carretera Luperon. Km. 5. Playa Dorada, Puerto Plata 547 Puerto Plata DOMINICAN REPUBLIC";
      let source = "apple";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = source;
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onSunscapePuertoPlata(results) {
      // vars
      let resortPropertyId = "SPPD";
      let resortName =
        "Sunscape Puerto Plata Dominican Republic By AMR Collection All Inclusive";
      let destination = "Puerto Plata, Dominican Republic";
      let numNights = 4;
      let resortAddress =
        "Carretera Luperon. Km. 5. Playa Dorada, Puerto Plata 547 Puerto Plata DOMINICAN REPUBLIC";
      let source = "apple";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = source;
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onOccidentalCozumel(results) {
      // vars
      let resortPropertyId = "OCAI";
      let resortName = "Occidental Cozumel All Inclusive";
      let destination = "Cozumel, MEXICO";
      let numNights = 4;
      let resortAddress =
        "KM 16.6 Carretera Sur, El Cedral, San Francisco, Palancar Cozumel, QR 77600 MEXICO";
      let source = "apple";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9) +
          Math.random().toString(36).substring(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = source;
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onSunPeaksGrandHotel(results) {
      // vars
      let resortPropertyId = "SPGH";
      let resortName = "Sun Peaks Grand Hotel and Conference Centre";
      let destination = "British Columbia, Canada";
      let numNights = 4;
      let resortAddress = "3240 Village Way, Sun Peaks, V0E 5N0, BC, Canada";
      let source = "hc";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onEastClareGolfVillage(results) {
      // vars
      let resortPropertyId = "ECGV";
      let resortName = "East Clare Golf Village";
      let destination = "County Clare, Ireland";
      let numNights = 7;
      let resortAddress =
        "Coolreagh House, Bodyke, V94 RWH9, County Clare, Ireland";
      let source = "hc";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onBahiaPrincipe(results) {
      // vars
      let resortPropertyId = "BPGJ";
      let resortName = "Bahia Principe Grand Jamaica All Inclusive";
      let destination = "Jamaica";
      let numNights = 4;
      let resortAddress = "Salt Coppers Villa Runaway Bay, St Ann JAMAICA";
      let source = "tb";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onRoyalDecameron(results) {
      // vars
      let resortPropertyId = "RDCB";
      let resortName = "Royal Decameron Cornwall Beach All Inclusive";
      let destination = "Jamaica";
      let numNights = 4;
      let resortAddress =
        "2, Gloucester Avenue Montego Bay, Saint James JAMAICA";
      let source = "tb";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onHotelRiu(results) {
      // vars
      let resortPropertyId = "HCRP2";
      let resortName = "Hotel Riu Plaza New York Times Square";
      let destination = "New York City, NY";
      let numNights = 3;
      let resortAddress =
        "305 West, 46th Street, Manhattan, New York, NY, 10036";
      let source = "hc";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onHotelRiuMacys(results) {
      // vars
      let resortPropertyId = "HCRP";
      let resortName = "Hotel Riu Plaza New York Times Square";
      let destination = "New York City, NY";
      let numNights = 3;
      let resortAddress =
        "305 West, 46th Street, Manhattan, New York, NY, 10036";
      let source = "hc";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onRowNyc(results) {
      // vars
      let resortPropertyId = "HCRH2";
      let resortName = "Row NYC";
      let destination = "New York City, NY";
      let numNights = 3;
      let resortAddress = "700 8th Avenue, New York, NY, 10036";
      let source = "hc";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onRowNycMacys(results) {
      // vars
      let resortPropertyId = "HCRH";
      let resortName = "Row NYC";
      let destination = "New York City, NY";
      let numNights = 3;
      let resortAddress = "700 8th Avenue, New York, NY, 10036";
      let source = "hc";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onTheManhattan(results) {
      // vars
      let resortPropertyId = "HCMT2";
      let resortName = "The Manhattan at Times Square Hotel";
      let destination = "New York City, NY";
      let numNights = 3;
      let resortAddress = "790 7th Ave, New York, NY, 10019";
      let source = "hc";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onTheManhattanMacys(results) {
      // vars
      let resortPropertyId = "HCMT";
      let resortName = "The Manhattan at Times Square Hotel";
      let destination = "New York City, NY";
      let numNights = 3;
      let resortAddress = "790 7th Ave, New York, NY, 10019";
      let source = "hc";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onHotelHendricks(results) {
      // vars
      let resortPropertyId = "HCHE2";
      let resortName = "Hotel Hendricks";
      let destination = "New York City, NY";
      let numNights = 3;
      let resortAddress = "25 W 38th St, New York, NY 10018";
      let source = "hc";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onHotelHendricksMacys(results) {
      // vars
      let resortPropertyId = "HCHE";
      let resortName = "Hotel Hendricks";
      let destination = "New York City, NY";
      let numNights = 3;
      let resortAddress = "25 W 38th St, New York, NY 10018";
      let source = "hc";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onJohnstownEstate(results) {
      // vars
      let resortPropertyId = "TJOE";
      let resortName = "Johnstown Estate";
      let destination = "Enfield, Co. Meath, Ireland";
      let numNights = 5;
      let resortAddress = "Johnstown Estate, Enfield, Co. Meath, Ireland";
      let source = "hc";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onKahanaFallsResort(results) {
      // vars
      let resortPropertyId = "KFAL";
      let resortName = "Kahana Falls Resort";
      let destination = "Maui, HI";
      let numNights = 7;
      let resortAddress =
        "4260 Lower Honoapiilani Road Lahaina, Hawaii 96761 USA";
      let source = "hsi";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onPonoKaiResort(results) {
      // vars
      let resortPropertyId = "PONO";
      let resortName = "Pono Kai Resort";
      let destination = "Kauai, HI (NOT MAUI)";
      let numNights = 7;
      let resortAddress = "4-1250 Kuhio Highway Kapaa, Hawaii 96746 USA";
      let source = "hsi";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onTheMakaiClub(results) {
      // vars
      let resortPropertyId = "TMAK";
      let resortName = "The Makai Club";
      let destination = "Kauai, HI (NOT MAUI)";
      let numNights = 7;
      let resortAddress = "4180 Lei O Papa Road Princeville, Hawaii 96722 USA";
      let source = "hsi";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onTheGuestHouseAtGraceland(results) {
      // vars
      let resortPropertyId = "GHGL";
      let resortName = "The Guest House at Graceland";
      let destination = "Memphis, Tennessee";
      let numNights = 3;
      let resortAddress =
        "3600 Elvis Presley Blvd, Memphis, 38116, TN, United States of America";
      let source = "hc";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onHuHotel(results) {
      // vars
      let resortPropertyId = "HCHH";
      let resortName = "Hu. Hotel";
      let destination = "Memphis, Tennessee";
      let numNights = 3;
      let resortAddress =
        "79 Madison Avenue, Memphis, 38103, TN, United States of America";
      let source = "hc";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onCrownePlazaHotelMemphisDowntown(results) {
      // vars
      let resortPropertyId = "CPMD";
      let resortName = "Crowne Plaza Memphis Downtown Hotel";
      let destination = "Memphis, Tennessee";
      let numNights = 3;
      let resortAddress =
        "300 N 2nd Street, Memphis, 38105, TN, United States of America";
      let source = "hc";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onSheratonMemphisDowntown(results) {
      // vars
      let resortPropertyId = "HSMD";
      let resortName = "Sheraton Memphis Downtown";
      let destination = "Memphis, Tennessee";
      let numNights = 3;
      let resortAddress =
        "250 N Main St, Memphis, 38103, TN, United States of America";
      let source = "hc";
      // vars

      // not correct inventory
      if (results.data[0].resortPropertyId !== resortPropertyId) {
        this.error = `not ${resortName} inventory`;
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === resortPropertyId &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add(numNights, "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = destination;

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = numNights;
        i.resortAddress = resortAddress;
        i.resortName = resortName;
        i.resortPropertyId = resortPropertyId;
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: resortPropertyId,
        destination: destination,
        resortName: resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: source,
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(resortPropertyId);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = resortPropertyId;
    },
    async onTheCapitolHotelAnAscendHotel(results) {
      // not capitol inventory
      if (results.data[0].resortPropertyId !== "CHAH") {
        this.error = "not capitol inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "CHAH" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("3", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "Nashville, Tennessee";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 3;
        i.resortAddress =
          "711 Union Street, Nashville, 37219, TN, United States of America";
        i.resortName = "The Capitol Hotel Downtown";
        i.resortPropertyId = "CHAH";
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "CHAH",
        destination: "Nashville, Tennessee",
        resortName: "The Capitol Hotel Downtown",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "hc",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("CHAH");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = "CHAH";
    },
    async onMillenniumMaxwellHouse(results) {
      // not millennium house inventory
      if (results.data[0].resortPropertyId !== "HMMH") {
        this.error = "not millennium house inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "HMMH" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("3", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "Nashville, Tennessee";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 3;
        i.resortAddress =
          "2025 Rosa L. Park Boulevard, Nashville, 37228, TN, United States of America";
        i.resortName = "Millennium Maxwell House";
        i.resortPropertyId = "HMMH";
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "HMMH",
        destination: "Nashville, Tennessee",
        resortName: "Millennium Maxwell House",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "hc",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("HMMH");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = "HMMH";
    },
    async onTheInnAtOpryland(results) {
      // not gaylord inventory
      if (results.data[0].resortPropertyId !== "HCIO") {
        this.error = "not gaylord inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "HCIO" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("3", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "Nashville, Tennessee";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 3;
        i.resortAddress =
          "2401 Music Valley Dr, Nashville, 37214, TN, United States of America";
        i.resortName = "The Inn at Opryland";
        i.resortPropertyId = "HCIO";
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "HCIO",
        destination: "Nashville, Tennessee",
        resortName: "The Inn at Opryland",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "hc",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("HCIO");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = "HCIO";
    },
    async onGaylordOprylandResort(results) {
      // not gaylord inventory
      if (results.data[0].resortPropertyId !== "HGOH") {
        this.error = "not gaylord inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "HGOH" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("3", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "Nashville, Tennessee";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 3;
        i.resortAddress =
          "2800 Opryland Drive, Nashville, 37214, TN, United States of America";
        i.resortName = "Gaylord Opryland Resort";
        i.resortPropertyId = "HGOH";
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "HGOH",
        destination: "Nashville, Tennessee",
        resortName: "Gaylord Opryland Resort",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "hc",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("HGOH");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = "HGOH";
    },
    async onRenaissanceNashville(results) {
      // not renaissance nashville inventory
      if (results.data[0].resortPropertyId !== "HCRN") {
        this.error = "not renaissance nashville inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "HCRN" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("3", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "Nashville, Tennessee";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 3;
        i.resortAddress =
          "611 Commerce St, Nashville, 37203, TN, United States of America";
        i.resortName = "Renaissance Nashville Hotel";
        i.resortPropertyId = "HCRN";
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "HCRN",
        destination: "Nashville, Tennessee",
        resortName: "Renaissance Nashville Hotel",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "hc",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("HCRN");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = "HCRN";
    },
    async onHolstonHouseNashville(results) {
      // not holston inventory
      if (results.data[0].resortPropertyId !== "HHHN") {
        this.error = "not holston inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "HHHN" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("3", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "Nashville, Tennessee";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 3;
        i.resortAddress =
          "118 7th Avenue North, Nashville, 37203, TN, United States of America";
        i.resortName = "Holston House Nashville";
        i.resortPropertyId = "HHHN";
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "HHHN",
        destination: "Nashville, Tennessee",
        resortName: "Holston House Nashville",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "hc",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("HHHN");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = "HHHN";
    },
    async onKnightsbrook(results) {
      // not knightsbrook inventory
      if (results.data[0].resortPropertyId !== "KBR") {
        this.error = "Not Knightsbrook Inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;
      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "KBR" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // removing dates that should not be displayed @Greg
      let filterTheseDatesOut = moment.range("2021-06-21", "2021-08-31");
      data.forEach((i, index) => {
        let filteredDate = filterTheseDatesOut.contains(moment(i.checkInDate));
        if (filteredDate) data.splice(index, 1);
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);
        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("7", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "Dublin Area, Ireland";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "full";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 7;
        i.resortAddress = "Dublin Road, Trim, Co. Meath, C15 WYF4, Ireland";
        i.resortName = "Knightsbrook Hotel Spa and Golf Resort";
        i.resortPropertyId = "KBR";
        i.roomCost = parseInt(i.roomCost);
        i.source = "kbr";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "KBR",
        destination: "Dublin Area, Ireland",
        resortName: "Knightsbrook Hotel Spa and Golf Resort",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "kbr",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("KBR");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = firestoreData.id;
    },
    async onCastlemartyr(results) {
      // not castlemartyr inventory
      if (results.data[0].resortPropertyId !== "CMR") {
        this.error = "Not Castlemartyr Inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;
      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "CMR" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // removing dates that should not be displayed @Greg
      let filterTheseDatesOut = moment.range("2021-06-21", "2021-08-30");
      data.forEach((i, index) => {
        let filteredDate = filterTheseDatesOut.contains(moment(i.checkInDate));
        if (filteredDate) data.splice(index, 1);
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("7", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "County Cork, Ireland";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "full";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 7;
        i.resortAddress = "Castlemartyr P25 X300 Co. Cork Ireland";
        i.resortName = "Castlemartyr Resort";
        i.resortPropertyId = "CMR";
        i.roomCost = parseInt(i.roomCost);
        i.source = "cmr";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "CMR",
        destination: "County Cork, Ireland",
        resortName: "Castlemartyr Resort",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "cmr",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("CMR");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = "CMR";
    },
    async onDreamweeks(results) {
      // not dreamweeks inventory
      if (
        !results.data[0]["web-scraper-start-url"].includes("dreamvacationweek")
      ) {
        this.error = "Not Dreamweeks Inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;
      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i["web-scraper-order"] !== "" &&
          i.bedrooms !== "null" &&
          i.dates !== "null" &&
          i.nightlyRate !== "null" &&
          i.resortAddress !== "null" &&
          i.resortName !== "null" &&
          i.resortPropertyId !== "null" &&
          i.weeklyRate !== "null"
        );
      });
      // formatting
      data.forEach((i) => {
        // archived
        i.archived = false;
        // archived

        // bedrooms
        i.bedrooms = i.bedrooms.toLowerCase();
        let bedroomsTempVal = 0;
        i.bedroomsDisplay = "";

        if (i.bedrooms == "0" || i.bedrooms == "e") {
          i.bedroomsDisplay = "studio";
        } else if (i.bedrooms == "h") {
          i.bedroomsDisplay = "hotel";
          bedroomsTempVal = parseInt(i.bedrooms);
        } else {
          i.bedroomsDisplay = i.bedrooms;
          bedroomsTempVal = parseInt(i.bedrooms);
        }
        i.bedrooms = bedroomsTempVal;
        // bedrooms

        // checkInDate
        i.checkInDate = i.dates.split("-")[0].trim();
        i.checkInDate = moment(i.checkInDate, "MMM DD YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        // checkInDate

        // checkOutDate
        i.checkOutDate = i.dates.split("-")[1].trim();
        i.checkOutDate = moment(i.checkOutDate, "MMM DD YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkOutDate = new Date(i.checkOutDate)
          .toISOString()
          .substring(0, 10);
        // checkOutDate

        // dates
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        // dates

        // destination
        // i.destination = i.resortAddress
        //   .split(" ")[0]
        //   .replace(",", "")
        //   .toLowerCase()
        //   .trim();
        i.destination = this.trimAll(i.resortAddress);
        // destination

        // id
        // i.id =
        //   Math.random().toString(36).substr(2, 9) +
        //   Math.random().toString(36).substr(2, 9) +
        //   Math.random().toString(36).substr(2, 9) +
        //   Math.random().toString(36).substr(2, 9) +
        //   Math.random().toString(36).substr(2, 9);
        // id

        // kitchen
        let kitchen = "none";
        if (i.limitedKitchen != "null") kitchen = "limited";
        if (i.kitchen != "null") kitchen = "full";
        i.kitchen = kitchen;
        // kitchen

        //maxOccupancy
        i.maxOccupancy = parseInt(i.maxOccupancy);
        //maxOccupancy

        // numberOfNights
        var a = moment(i.checkOutDate);
        var b = moment(i.checkInDate);
        i.numberOfNights = a.diff(b, "days");
        // numberOfNights

        // resortAddress
        i.resortAddress = this.trimAll(i.resortAddress);
        // resortAddress

        // roomCost
        if (i.weeklyRate.includes("$"))
          i.weeklyRate = i.weeklyRate.replace("$", "");
        i.roomCost = parseInt(i.weeklyRate);
        // roomCost

        // source
        i.source = "dreamweeks";
        // source

        delete i.dates;
        delete i.weeklyRate;
        delete i.nightlyRate;
        delete i.limitedKitchen;
        delete i["web-scraper-order"];
        delete i["web-scraper-start-url"];
      });

      let firestoreData = {
        template: "inventoryListings",
        id: data[0].resortPropertyId,
        destination: data[0].destination,
        resortName: data[0].resortName,
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "dreamweeks",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(firestoreData.id);

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = firestoreData.id;
    },
    async onMauiBanyan(results) {
      // not maui banyan inventory
      if (results.data[0].resortPropertyId !== "BANY") {
        this.error = "Not Maui Banyan Vacation Club Inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "BANY" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;

        i.bedrooms = 0;
        i.bedroomsDisplay = "studio";

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("7", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "Maui, HI";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "full";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 7;
        i.resortAddress = "2575 South Kihei Road Kihei, Hawaii 96753 USA";
        i.resortName = "Maui Banyan Vacation Club";
        i.resortPropertyId = "BANY";
        i.roomCost = parseInt(i.roomCost);
        i.source = "hsi";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "BANY",
        destination: "Maui, HI",
        resortName: "Maui Banyan Vacation Club",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "hsi",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("BANY");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = "BANY";
    },
    async onGardensAtWestMaui(results) {
      // not gardens at west maui inventory
      if (results.data[0].resortPropertyId !== "GAWM") {
        this.error = "Not Gardens at West Maui Inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "GAWM" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("7", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "Maui, HI";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "full";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 7;
        i.resortAddress =
          "5500 Lower Honoapiilani Highway Kapalua, Hawaii 96761 USA";
        i.resortName = "Gardens at West Maui";
        i.resortPropertyId = "GAWM";
        i.roomCost = parseInt(i.roomCost);
        i.source = "hsi";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "GAWM",
        destination: "Maui, HI",
        resortName: "Gardens at West Maui",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "hsi",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("GAWM");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = "GAWM";
    },
    async onKahanaVillaVacationClub(results) {
      // not kahana villa vacation club inventory
      if (results.data[0].resortPropertyId !== "KVVC") {
        this.error = "Not Kahana Villa Vacation Club Inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "KVVC" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("7", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "Maui, HI";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "full";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 7;
        i.resortAddress =
          "4242 Lower Honoapiilani Highway Lahaina, Hawaii 96761 USA";
        i.resortName = "Kahana Villa Vacation Club";
        i.resortPropertyId = "KVVC";
        i.roomCost = parseInt(i.roomCost);
        i.source = "hsi";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "KVVC",
        destination: "Maui, HI",
        resortName: "Kahana Villa Vacation Club",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "hsi",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("KVVC");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = "KVVC";
    },
    async onMauiBeachVacationClub(results) {
      // not maui beach vacation club inventory
      if (results.data[0].resortPropertyId !== "MBVC") {
        this.error = "Not Maui Beach Vacation Club Inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "MBVC" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("7", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "Maui, HI";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "full";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 7;
        i.resortAddress = "515 South Kihei Road Kihei, Hawaii 96753 USA";
        i.resortName = "Maui Beach Vacation Club";
        i.resortPropertyId = "MBVC";
        i.roomCost = parseInt(i.roomCost);
        i.source = "hsi";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "MBVC",
        destination: "Maui, HI",
        resortName: "Maui Beach Vacation Club",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "hsi",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("MBVC");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = "MBVC";
    },
    async onSandsOfKahana(results) {
      // not sands of kahana inventory
      if (results.data[0].resortPropertyId !== "SOKR") {
        this.error = "Not Sands of Kahana Resort Inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "SOKR" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("7", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "Maui, HI";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "full";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 7;
        i.resortAddress =
          "4299 Lower Honoapiilani Road Lahaina, Hawaii 96761 USA";
        i.resortName = "Sands of Kahana Resort";
        i.resortPropertyId = "SOKR";
        i.roomCost = parseInt(i.roomCost);
        i.source = "hsi";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "SOKR",
        destination: "Maui, HI",
        resortName: "Sands of Kahana Resort",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "hsi",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("SOKR");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = "SOKR";
    },
    async onBayshoreVillasBali(results) {
      // not bayshore villas bali inventory
      if (results.data[0].resortPropertyId !== "HBSV") {
        this.error = "Not Bayshore Villas Bali Inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "HBSV" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("7", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "Bali, Indonesia";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 7;
        i.resortAddress = "Candi Dasa Street, Manggis, 80871, Bali, Indonesia";
        i.resortName = "Bayshore Villas";
        i.resortPropertyId = "HBSV";
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "HBSV",
        destination: "Bali, Indonesia",
        resortName: "Bayshore Villas",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "hc",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("HBSV");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = "HBSV";
    },
    async onCosmopolitanLasVegas(results) {
      // not cosmopolitan las vegas inventory
      if (results.data[0].resortPropertyId !== "HCOS") {
        this.error = "Not Cosmopolitan Las Vegas Inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "HCOS" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("3", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "Las Vegas, NV";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 3;
        i.resortAddress =
          "3708 Las Vegas Blvd S, Las Vegas, 89109, NV, United States of America";
        i.resortName = "Cosmopolitan Of Las Vegas";
        i.resortPropertyId = "HCOS";
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "HCOS",
        destination: "Las Vegas, NV",
        resortName: "Cosmopolitan Of Las Vegas",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "hc",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("HCOS");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = "HCOS";
    },
    async onMgmGrandLasVegas(results) {
      // not mgm grand las vegas inventory
      if (results.data[0].resortPropertyId !== "HMGM") {
        this.error = "Not MGM Grand Las Vegas Inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "HMGM" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("3", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "Las Vegas, NV";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 3;
        i.resortAddress =
          "3799 Las Vegas Blvd S, Las Vegas, 89109, NV, United States of America";
        i.resortName = "MGM Grand";
        i.resortPropertyId = "HMGM";
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "HMGM",
        destination: "Las Vegas, NV",
        resortName: "MGM Grand",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "hc",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("HMGM");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = "HMGM";
    },
    async onMirageLasVegas(results) {
      // not mirage las vegas inventory
      if (results.data[0].resortPropertyId !== "HMLV") {
        this.error = "Not Mirage Las Vegas Inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "HMLV" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("3", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "Las Vegas, NV";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 3;
        i.resortAddress =
          "3400 Las Vegas Blvd S, Las Vegas, 89109, NV, United States of America";
        i.resortName = "Mirage";
        i.resortPropertyId = "HMLV";
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "HMLV",
        destination: "Las Vegas, NV",
        resortName: "Mirage",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "hc",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("HMLV");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = "HMLV";
    },
    async onCaesarsPalaceLasVegas(results) {
      // not caesars palace las vegas inventory
      if (results.data[0].resortPropertyId !== "HCPL") {
        this.error = "Not Caesar's Palace Las Vegas Inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "HCPL" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items

      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("3", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "Las Vegas, NV";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 3;
        i.resortAddress =
          "3570 Las Vegas Boulevard South, Las Vegas, 89109, NV, United States of America";
        i.resortName = "Caesars Palace";
        i.resortPropertyId = "HCPL";
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "HCPL",
        destination: "Las Vegas, NV",
        resortName: "Caesars Palace",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "hc",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("HCPL");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = "HCPL";
    },
    async onMandalayBayLasVegas(results) {
      // not mandalay bay las vegas inventory
      if (results.data[0].resortPropertyId !== "MBLV") {
        this.error = "Not Mandalay Bay Las Vegas Inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "MBLV" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("3", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "Las Vegas, NV";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 3;
        i.resortAddress =
          "3950 Las Vegas Blvd S, Las Vegas, 89119, NV, United States of America";
        i.resortName = "Mandalay Bay";
        i.resortPropertyId = "MBLV";
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "MBLV",
        destination: "Las Vegas, NV",
        resortName: "Mandalay Bay",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "hc",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("MBLV");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = "MBLV";
    },
    async onParisLasVegas(results) {
      // not paris las vegas inventory
      if (results.data[0].resortPropertyId !== "HPLV") {
        this.error = "Not Paris Las Vegas Inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "HPLV" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("3", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "Las Vegas, NV";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 3;
        i.resortAddress =
          "3655 Las Vegas Blvd S, Las Vegas, 89109, NV, United States of America";
        i.resortName = "Paris Las Vegas";
        i.resortPropertyId = "HPLV";
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "HPLV",
        destination: "Las Vegas, NV",
        resortName: "Paris Las Vegas",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "hc",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("HPLV");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = "HPLV";
    },
    async onMichelangeloGrandHotelPrague(results) {
      // not michelangelo grand hotel prague inventory
      if (results.data[0].resortPropertyId !== "HCMG") {
        this.error = "not michelangelo grand hotel prague inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "HCMG" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("5", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "Prague, Czech Republic";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 5;
        i.resortAddress =
          "Vladislavova 1477/20, Prague, 110 00, Czech Republic";
        i.resortName = "Michelangelo Grand Hotel";
        i.resortPropertyId = "HCMG";
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "HCMG",
        destination: "Prague, Czech Republic",
        resortName: "Michelangelo Grand Hotel",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "hc",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("HCMG");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = "HCMG";
    },
    async onHiltonPragueOldTown(results) {
      // not hilton prague old town inventory
      if (results.data[0].resortPropertyId !== "HCHP") {
        this.error = "not hilton prague old town inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "HCHP" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("5", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "Prague, Czech Republic";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 5;
        i.resortAddress = "V Celnici 7, Prague, 110 00, Czech Republic";
        i.resortName = "Hilton Prague Old Town";
        i.resortPropertyId = "HCHP";
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "HCHP",
        destination: "Prague, Czech Republic",
        resortName: "Hilton Prague Old Town",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "hc",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("HCHP");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = "HCHP";
    },
    async onGrandiorHotelPrague(results) {
      // not Grandior Hotel Prague inventory
      if (results.data[0].resortPropertyId !== "HCGP") {
        this.error = "not Grandior Hotel Prague inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "HCGP" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("5", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "Prague, Czech Republic";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 5;
        i.resortAddress = "Na Porici 42, Prague, 11000, Czech Republic";
        i.resortName = "Grandior Hotel Prague";
        i.resortPropertyId = "HCGP";
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "HCGP",
        destination: "Prague, Czech Republic",
        resortName: "Grandior Hotel Prague",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "hc",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("HCGP");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = "HCGP";
    },
    async onLempireParis(results) {
      // not L'Empire Paris inventory
      if (results.data[0].resortPropertyId !== "HCLE") {
        this.error = "not L'Empire Paris inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "HCLE" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("5", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "Paris, France";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 5;
        i.resortAddress = "48 Rue De L Arbre Sec, Paris, 75001, Paris, France";
        i.resortName = "L'Empire Paris";
        i.resortPropertyId = "HCLE";
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "HCLE",
        destination: "Paris, France",
        resortName: "L'Empire Paris",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "hc",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("HCLE");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = "HCLE";
    },
    async onGoldenTulipHotelWashingtonOperaParis(results) {
      // not Golden Tulip Inventory
      if (results.data[0].resortPropertyId !== "HCGT") {
        this.error = "Not Golden Tulip Inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "HCGT" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("5", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "Paris, France";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 5;
        i.resortAddress = "50 Rue De Richelieu, Paris, 75001, Paris, France";
        i.resortName = "Golden Tulip Hotel Washington Opera";
        i.resortPropertyId = "HCGT";
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "HCGT",
        destination: "Paris, France",
        resortName: "Golden Tulip Hotel Washington Opera",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "hc",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("HCGT");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = "HCGT";
    },
    async onParisMarriotOperaAmbassador(results) {
      // not Paris Marriot Opera Ambassador inventory
      if (results.data[0].resortPropertyId !== "HCPM") {
        this.error = "not Paris Marriot Opera Ambassador inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "HCPM" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("5", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "Paris, France";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 5;
        i.resortAddress = "16 Boulevard Haussmann, Paris, 75009, Paris, France";
        i.resortName = "Paris Marriott Opera Ambassador";
        i.resortPropertyId = "HCPM";
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "HCPM",
        destination: "Paris, France",
        resortName: "Paris Marriott Opera Ambassador",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "hc",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("HCPM");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = "HCPM";
    },
    async onNapoleanHotelRome(results) {
      // not Napolean Hotel Rome inventory
      if (results.data[0].resortPropertyId !== "HCNH") {
        this.error = "not Napolean Hotel Rome inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "HCNH" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("5", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "Rome, Italy";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 5;
        i.resortAddress =
          "Piazza Vittorio Emanuele II 105, Rome, 185, RM, Italy";
        i.resortName = "Napoleon Hotel";
        i.resortPropertyId = "HCNH";
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "HCNH",
        destination: "Rome, Italy",
        resortName: "Napoleon Hotel",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "hc",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("HCNH");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = "HCNH";
    },
    async onHotelMancinoRome(results) {
      // not hotel mancino 12 inventory
      if (results.data[0].resortPropertyId !== "HCHM") {
        this.error = "not hotel mancino 12 inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "HCHM" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("5", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "Rome, Italy";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 5;
        i.resortAddress = "Via del Mancino 12, Rome, 187, RM, Italy";
        i.resortName = "Hotel Mancino 12";
        i.resortPropertyId = "HCHM";
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "HCHM",
        destination: "Rome, Italy",
        resortName: "Hotel Mancino 12",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "hc",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("HCHM");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = "HCHM";
    },
    async onExeInternationalPalaceRome(results) {
      // not exe international palace rome inventory
      if (results.data[0].resortPropertyId !== "HCEI") {
        this.error = "not exe international palace rome inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "HCEI" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("5", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "Rome, Italy";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 5;
        i.resortAddress = "Via Nazionale, 46, 00184 Roma RM, Italy";
        i.resortName = "Exe International Palace";
        i.resortPropertyId = "HCEI";
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "HCEI",
        destination: "Rome, Italy",
        resortName: "Exe International Palace",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "hc",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("HCEI");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = "HCEI";
    },
    async onViennaMarriot(results) {
      // not vienna marriot inventory
      if (results.data[0].resortPropertyId !== "HCVM") {
        this.error = "not vienna marriot inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "HCVM" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("5", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "Vienna, Austria";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 5;
        i.resortAddress = "Parkring 12 A, Vienna, 1010, Vienna, Austria";
        i.resortName = "Vienna Marriott Hotel";
        i.resortPropertyId = "HCVM";
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "HCVM",
        destination: "Vienna, Austria",
        resortName: "Vienna Marriott Hotel",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "hc",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("HCVM");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = "HCVM";
    },
    async onAmbassadorHotelVienna(results) {
      // not ambassador hotel vienna inventory
      if (results.data[0].resortPropertyId !== "HCAH") {
        this.error = "not ambassador hotel vienna inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "HCAH" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("5", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "Vienna, Austria";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 5;
        i.resortAddress = "Kaerntner Strasse 22, Vienna, 1010, Vienna, Austria";
        i.resortName = "Ambassador Hotel";
        i.resortPropertyId = "HCAH";
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "HCAH",
        destination: "Vienna, Austria",
        resortName: "Ambassador Hotel",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "hc",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("HCAH");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = "HCAH";
    },
    async onGrandFerdinandVienna(results) {
      // not grand ferdinand vienna inventory
      if (results.data[0].resortPropertyId !== "HCGF") {
        this.error = "not grand ferdinand vienna inventory";
        await this.$store.dispatch("setLoading", false);
        return;
      }

      let data = results.data;

      // removing blank and null entries
      data = data.filter((i) => {
        return (
          i.resortPropertyId === "HCGF" &&
          i.checkInDate !== "" &&
          i.roomCost !== "" &&
          i.bedrooms !== "" &&
          i.maxOccupancy !== ""
        );
      });

      // formatting & creating items
      data.forEach((i) => {
        i.archived = false;
        i.bedroomsDisplay = i.bedrooms;
        i.bedrooms = parseInt(i.bedrooms);

        i.checkInDate = moment(i.checkInDate, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        );
        i.checkInDate = new Date(i.checkInDate).toISOString().substring(0, 10);
        i.checkOutDate = moment(i.checkInDate)
          .add("5", "d")
          .toISOString()
          .substring(0, 10);
        i.dateSubmitted = new Date().toISOString().substring(0, 10);
        i.dateUpdated = "";
        i.destination = "Vienna, Austria";

        i.id =
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9) +
          Math.random().toString(36).substr(2, 9);

        i.kitchen = "none";
        i.maxOccupancy = parseInt(i.maxOccupancy);
        i.numberOfNights = 5;
        i.resortAddress = "Schubertring 10-12, Vienna, 1010, Austria";
        i.resortName = "Grand Ferdinand Vienna";
        i.resortPropertyId = "HCGF";
        i.roomCost = parseInt(i.roomCost);
        i.source = "hc";
      });

      let firestoreData = {
        template: "inventoryListings",
        id: "HCGF",
        destination: "Vienna, Austria",
        resortName: "Grand Ferdinand Vienna",
        items: data,
        dateSubmitted: new Date().toISOString().substring(0, 10),
        source: "hc",
      };

      let docRef = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc("HCGF");

      await docRef.set(firestoreData, { merge: true });
      await this.$store.dispatch("setLoading", false);
      this.jobComplete = "HCGF";
    },
  },
  watch: {
    source() {
      this.jobComplete = "";
    },
  },
};
</script>
